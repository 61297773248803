import styles from './css/menu.module.scss';
import MenuItem from './MenuItem';
import route from '~/const/adminRoute';

export default defineComponent({
  name: 'AdminMenu',
  props: {
    isSidebarOpen: {
      type: Boolean,
    },
  },
  setup(props: { isSidebarOpen: boolean }) {
    return () => {
      return (
        <div class={styles.menuContainer}>
          <div class="h-80px box-border pt-20px">
            <img
              src="/logo.png"
              alt="logo"
              class={`h-30px ml-15px transition-all-300 ${props.isSidebarOpen ? 'transform-scale-130 ml-20px ' : ''}`}
            ></img>
            <span v-show={props.isSidebarOpen} class={styles.logoTitle}>
              Dream-Blog
            </span>
          </div>
          <ul class={styles.menu}>
            {route.map((item) => {
              return item.isTitle ? (
                <div class="h-20px ml-25px font-700 font-size-14px my-8px">
                  <span v-show={props.isSidebarOpen} class={styles.menuTitle}>
                    {item.name}
                  </span>
                </div>
              ) : (
                <MenuItem isSidebarOpen={props.isSidebarOpen} item={item}></MenuItem>
              );
            })}
          </ul>
        </div>
      );
    };
  },
});

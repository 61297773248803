import type { PropType } from 'vue';
import styles from './css/smMenu.module.scss';
import { Avatar, SmMenuItem } from '#components';
import useStore from '~/store';
import type { IMenuItemDto } from '~/http/types/menuItem';
import IconHouse from '~icons/noto/house-with-garden';

export default defineComponent({
  name: 'SmMenu',
  props: {
    menu: {
      type: Array as PropType<IMenuItemDto[]>,
      default: () => [],
    },
  },
  emits: ['close'],

  setup(props: { menu: IMenuItemDto[] }, { emit }) {
    const store = useStore();

    const sortMenu = computed(() => {
      const [childrenMenu, aloneMenu] = lodashPartition(props.menu, (item) => item.children && item.children?.length);
      return [...aloneMenu, ...childrenMenu];
    });

    const onClickTo = (path = '/') => {
      navigateTo(path);
      emit('close');
    };

    return () => {
      return (
        <div class={styles.menuContent}>
          <div class={styles.head}>
            <Avatar size={90}> </Avatar>
            <div class={styles.info}>
              <div>
                <span>文章</span>
                <span>{store.articleTagTypeCount.articleCount}</span>
              </div>
              <div>
                <span>类型</span>
                <span>{store.articleTagTypeCount.typeCount}</span>
              </div>
              <div>
                <span>标签</span>
                <span>{store.articleTagTypeCount.tagCount}</span>
              </div>
            </div>
          </div>
          <div class={styles.contentSpace}></div>
          <div class={styles.menu}>
            <div
              onClick={() => onClickTo()}
              class="flex-items-center cursor-pointer rounded-8px c-fontPrimary  transition-all-300 hover:(bg-primary c-#fff!) py-8px px-12px"
            >
              <IconHouse />
              <span class="ml-8px font-size-18px color-inherit ">首页</span>
            </div>
            {sortMenu.value.map((item) => (
              <SmMenuItem item={item} onClickTo={onClickTo}></SmMenuItem>
            ))}
          </div>
        </div>
      );
    };
  },
});

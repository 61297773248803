<template>
  <div class="author-info box-border flex flex-col relative h-300px w-100% overflow-hidden transition-height-300">
    <div class="relative w-inherit h-105px transition-inherit author-bgc">
      <img :src="getCdnPath('img/avatarBgc.webp')" alt="avatarBgc" class="w-inherit h-inherit" />
    </div>
    <div class="w-100% flex justify-center absolute top-50px">
      <Avatar @click="onClickToLogin"></Avatar>
    </div>

    <span>Dream-Life</span>
    <div>
      treasure every day
      <client-only>
        <icon-ghost class="ml-5px"></icon-ghost>
      </client-only>
    </div>
    <div>
      <div>
        <span>文章</span>
        <span>{{ store.articleTagTypeCount.articleCount }}</span>
      </div>
      <div>
        <span>类型</span>
        <span>{{ store.articleTagTypeCount.typeCount }}</span>
      </div>
      <div>
        <span>标签</span>
        <span>{{ store.articleTagTypeCount.tagCount }}</span>
      </div>
    </div>

    <div>
      <div class="icons">
        <nuxt-link to="https://github.com/Mychen3" target="_blank" rel="noopener noreferrer">
          <icon-github></icon-github>
        </nuxt-link>
        <nuxt-link to="https://twitter.com/creatDreamBoy" target="_blank" rel="noopener noreferrer">
          <icon-twitter class="c-[#1D9BF0]"></icon-twitter>
        </nuxt-link>
        <nuxt-link to="mailto:chen97491530@gmail.com" rel="noopener noreferrer">
          <icon-email class="c-[#FFE01B]"></icon-email>
        </nuxt-link>
        <nuxt-link ref="noopener noreferrer" target="_blank" to="https://music.163.com/#/user/home?id=382683355">
          <icon-neteasecloudmusic class="c-[#D43C33] font-size-19.5px"></icon-neteasecloudmusic>
        </nuxt-link>
        <nuxt-link ref="noopener noreferrer" target="_blank" to="https://t.me/WroldDream">
          <icon-telegram class="c-[#26A5E4] font-size-19.5px"></icon-telegram>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useArticleTagTypeCount } from '~/http/article';
import IconEmail from '~icons/mdi/email';
import IconGithub from '~icons/mdi/github';
import IconGhost from '~icons/noto/ghost';
import IconNeteasecloudmusic from '~icons/simple-icons/neteasecloudmusic';
import IconTelegram from '~icons/simple-icons/telegram';
import IconTwitter from '~icons/mdi/twitter';
import useStore from '~/store';

const store = useStore();
if (store.articleTagTypeCount.articleCount === 0) {
  const { data } = await useArticleTagTypeCount();
  store.setArticleTagTypeCount(data.value.data);
}

const onClickToLogin = () => {
  navigateTo('/login');
};
</script>

<style scoped lang="scss">
.icons {
  display: grid;
  font-size: 22px;
  grid-template-columns: repeat(5, 25px);
  column-gap: 20px;
  align-items: center;

  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
    color: inherit;
  }

  svg {
    cursor: pointer;
    transition: transform 300ms ease;

    &:hover {
      transform: scale(1.3);
    }
  }
}

.author-info {
  @include fade-in-Animation;
  @include cardContent;

  & > span:nth-child(3) {
    font-family: 'lobster', 'serif';
    color: var(--font-color);
    text-align: center;
    font-size: 18px;
    padding-top: 25px;
    cursor: pointer;
    transition: color 300ms ease;

    &:hover {
      color: var(--colorPrimary);
    }
  }

  & > div:nth-child(4) {
    color: var(--font-color);
    padding-top: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
  }

  & > div:nth-child(5) {
    display: flex;
    justify-content: space-around;
    margin: 15px 0;
    padding: 0 25px;

    & > div {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      span {
        transition: color 300ms ease;
        color: var(--font-color);
      }

      &:hover {
        span {
          color: var(--colorPrimary);
        }
      }

      & > span:nth-child(2) {
        font-family: 'lobster', 'serif';
        margin-top: 10px;
        text-align: center;
      }
    }
  }

  & > div:nth-child(6) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.author-bgc {
  position: relative;

  &:before {
    content: '';
    height: 20px;
    position: absolute;
    bottom: 0;
    z-index: -1;
    width: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--bgc-body));
  }
}
</style>

<template>
  <div class="flex flex-col quick-scroll c-fontPrimary" :class="windowScroll <= 0 ? 'in-fade' : 'fade-in'">
    <prime-speed-dial :model="items" direction="up" class="right-0px bottom-70px" button-class="w-30px h-30px">
      <template #item="{ item, onClick }">
        <div v-tooltip.left="item.label" role="button" class="btn-item" @click="onClick">
          <icons :name="item.icon" class="font-size-19px"></icons>
        </div>
      </template>
    </prime-speed-dial>
    <div class="btn-item" role="button" @click.stop="onClickSetScrollTop">
      <upload-outline-loop class="font-size-19px"></upload-outline-loop>
    </div>
    <div class="btn-item role='button' translate-y-10px" @click.stop="onClickSetScrollBottom">
      <upload-outline-loop class="font-size-19px transform-rotate-180"></upload-outline-loop>
    </div>
  </div>
</template>

<script setup lang="ts">
import UploadOutlineLoop from '~icons/line-md/upload-outline-loop';
import showToast from '~/components/publicCom/toast';

const windowScroll = ref(0);

const items = ref([
  {
    label: '刷新',
    icon: 'mdi/mdiRefresh',
    command: () => {
      location.reload();
    },
  },
  {
    label: '复制链接',
    icon: 'mdi/shareVariantOutline',
    command: async () => {
      const path = window.location.href;
      try {
        await navigator.clipboard.writeText(path);
        showToast('复制链接成功啦！');
      } catch (err) {
        console.error('复制失败: ', err);
      }
    },
  },
]);

const setScrollTop = () => {
  windowScroll.value = document.documentElement.scrollTop;
};
useThrottleEventListener('scroll', 500, setScrollTop);

const onClickSetScrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const onClickSetScrollBottom = () => {
  const currentHeight = document.documentElement.scrollTop + document.documentElement.clientHeight;
  const windowHeight = document.documentElement.scrollHeight + 0.5;
  if (currentHeight === windowHeight) return;
  window.scrollTo({
    top: windowHeight,
    behavior: 'smooth',
  });
};
</script>

<style scoped lang="scss">
@keyframes slide-in-right {
  0% {
    transform: translateX(80px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  100% {
    transform: translateX(80px);
    opacity: 1;
  }
}

.fade-in {
  animation: slide-in-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.in-fade {
  animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.quick-scroll {
  position: fixed;
  bottom: 80px;
  right: 10px;
  z-index: 999;

  & > div:nth-child(1) {
    margin-bottom: 10px;
  }

  .btn-item {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--primary-color-text);
    transition: background-color 300ms ease;

    &:hover {
      background-color: #d97706;
    }
  }
}
</style>

<template>
  <module-card title="Weibo Hot" content-class="h-260px">
    <template #icon>
      <icon-sinaweibo class="c-[#E6162D] mr-5px"></icon-sinaweibo>
    </template>
    <div class="list">
      <nuxt-link
        v-for="item in request?.data"
        :key="item.id"
        target="_blank"
        rel="noopener noreferrer"
        class="c-fontPrimary flex-items-center justify-between cursor-pointer"
        :to="item.url"
      >
        <div class="flex-items-center">
          <span class="font-lobster-serif mr-10px" :class="[item.realpos <= 3 ? 'c-[#f26d5f]' : 'c-[#ff8200]']">{{
            item.realpos
          }}</span>
          <div class="main-text">{{ item.word }}</div>
        </div>
        <div
          class="w-18px h-18px c-[#fff] rounded-3px font-size-13px flex-center"
          :style="{ backgroundColor: item.labelBgc }"
        >
          {{ item.labelName }}
        </div>
      </nuxt-link>
    </div>
  </module-card>
</template>

<script setup lang="ts">
import { useWeiboHotList } from '~/http/other';
import IconSinaweibo from '~icons/simple-icons/sinaweibo';

const { data: request } = await useWeiboHotList();
</script>

<style scoped lang="scss">
.list {
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 6px;
  margin-top: 15px;
  height: calc(100% - 33px);
  overflow-y: auto;
  scrollbar-width: none;
  align-content: start;
  padding: 0 3px;

  & > a {
    &:hover {
      .main-text {
        color: var(--colorPrimary);
      }
    }
  }
}

.main-text {
  transition: color 300ms ease;
  @include textEllipsis;
  width: 220px;
}
</style>

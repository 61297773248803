import { onMounted } from 'vue';
import { isEmpty } from 'lodash';
import { storeToRefs } from 'pinia';
import { usePrimeVue } from 'primevue/config';
import styles from './styles/index.module.scss';
import IconLineMdLoopSunny from '~icons/line-md/sunny-filled-loop-to-moon-filled-loop-transition';
import IconLineMdSunRisingFilledLoop from '~icons/line-md/sun-rising-filled-loop';
import { LocalStorageKey } from '~/const/sessionKey';
import { Scheme } from '~/const/public';
import useStore from '~/store';

export default defineComponent({
  name: 'ThemeSwitch',
  setup() {
    const store = useStore();
    const dayjs = useDayjs();
    const { isDark } = storeToRefs(store);
    let htmlDom: HTMLElement | undefined;
    const PrimeVue = usePrimeVue();

    const initScheme = () => {
      const scheme = localStorage.getItem(LocalStorageKey.colorScheme);
      if (!isEmpty(scheme)) {
        if (scheme === Scheme.DARK) {
          store.setDark(true);
          PrimeVue.changeTheme('light', 'dark', 'theme-like');
          htmlDom && (htmlDom.className = Scheme.DARK);
        }
      } else if (canCurrentTime()) {
        store.setDark(true);
        PrimeVue.changeTheme('light', 'dark', 'theme-like');
        htmlDom && (htmlDom.className = Scheme.DARK);
      }
    };

    const canCurrentTime = () => {
      return dayjs().hour() > 18;
    };
    const changeScheme = () => {
      isDark.value ? htmlDom?.removeAttribute('class') : htmlDom && (htmlDom.className = Scheme.DARK);
      localStorage.setItem(LocalStorageKey.colorScheme, isDark.value ? Scheme.LIGHT : Scheme.DARK);
      PrimeVue.changeTheme(
        isDark.value ? Scheme.DARK : Scheme.LIGHT,
        isDark.value ? Scheme.LIGHT : Scheme.DARK,
        'theme-like',
      );
      store.setDark(!isDark.value);
    };

    onMounted(() => {
      htmlDom = document.getElementsByTagName('html')[0];
      initScheme();
    });

    const onChange = (event: Event) => {
      changeScheme();
      event.stopPropagation();
    };
    return () => {
      return (
        <div
          class={`cursor-pointer ${styles.content} ${isDark.value ? 'bg-[#2f2f2f]' : 'bg-[#414B5529]'}`}
          onClick={onChange}
        >
          <div class={isDark.value ? 'transform-translate-x-22px' : undefined}>
            {isDark.value ? (
              <IconLineMdLoopSunny class={styles.lightIcon} />
            ) : (
              <IconLineMdSunRisingFilledLoop class={styles.darkIcon} />
            )}
          </div>
        </div>
      );
    };
  },
});

import dayjs from 'dayjs';

export const removeFilteredCharacters = (text: string, filterArray: Array<string>) => {
  const pattern = new RegExp(`[${filterArray.join('\\')}]`, 'g');
  return text.replace(pattern, '');
};

export const isEmailValid = (email: string) => {
  const pattern = /^[A-Za-z0-9\u4E00-\u9FA5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
  return pattern.test(email);
};

export const isHttpCdn = (httpUrl: string) => {
  return httpUrl.startsWith(import.meta.env.VITE_CDN);
};
export const isResultSuccess = (result: Response<unknown>) => {
  return result.status === 200 && result.success;
};

// bytes换算gb
export const bytesToGB = (bytes: number) => {
  return bytes / 1024 ** 3;
};

// bytes换算mb
export const bytesToMB = (bytes: number) => {
  return bytes / 1024 ** 2;
};
// bytes换算kb
export const bytesToKb = (bytes: number) => {
  return bytes / 1024;
};

export const dateFormat = (time: string, format = 'YYYY-MM-DD') => {
  return dayjs(time).format(format);
};

<template>
  <div class="w-inherit card-box">
    <div class="bg-[var(--bgc-box)] rounded-8px h-50px px-15px leading-40px flex-items-center">
      <client-only>
        <icon-open-book class="font-size-22px transition-color-300"></icon-open-book>
      </client-only>
      <div class="text-content c-fontPrimary transition-color-300">{{ data?.data.content }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useOneMotivation } from '~/http/motivation';
import IconOpenBook from '~icons/noto/open-book';

const { data } = await useOneMotivation();
</script>

<style scoped lang="scss">
.card-box {
  border-radius: 6px;
  box-sizing: border-box;
  margin: 8px 0;
  border: 1px solid var(--card-borderColor);
  box-shadow: var(--card-shadow);
  cursor: pointer;
  transition: all 500ms ease;

  &:hover {
    border-color: var(--colorPrimary);
    box-shadow: var(--hover-card-shadow);

    .text-content {
      color: var(--colorPrimary);
    }
  }
}

.text-content {
  font-size: 16px;
  padding-left: 20px;
  font-weight: 900;
  @include textManyEllipsis(1);
}
</style>

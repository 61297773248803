import revive_payload_client_isoe4DKnXU from "E:/my_project/blog_nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0_6tdx5c2mho3xj3v5waid7oaqrq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9SSIEpVLhs from "E:/my_project/blog_nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0_6tdx5c2mho3xj3v5waid7oaqrq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_T2iLcys8vd from "E:/my_project/blog_nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0_6tdx5c2mho3xj3v5waid7oaqrq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_GPTp2QfHtT from "E:/my_project/blog_nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0_6tdx5c2mho3xj3v5waid7oaqrq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_fZ5HF6lhAk from "E:/my_project/blog_nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0_6tdx5c2mho3xj3v5waid7oaqrq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_fMrutYnnVP from "E:/my_project/blog_nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0_6tdx5c2mho3xj3v5waid7oaqrq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UblzgfJayh from "E:/my_project/blog_nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0_6tdx5c2mho3xj3v5waid7oaqrq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_pfZLgywtdQ from "E:/my_project/blog_nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.26.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "E:/my_project/blog_nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5Tr9G6M6QT from "E:/my_project/blog_nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0_6tdx5c2mho3xj3v5waid7oaqrq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_oBpFtQ72gP from "E:/my_project/blog_nuxt/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.26.0/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "E:/my_project/blog_nuxt/.nuxt/primevue-plugin.mjs";
import plugin_client_m2wFZGtZZK from "E:/my_project/blog_nuxt/node_modules/.pnpm/nuxt-primevue@3.0.0_magicast@0.3.5_rollup@4.26.0_vue@3.5.12_typescript@5.6.3_/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import unocss_MzCDxu9LMj from "E:/my_project/blog_nuxt/.nuxt/unocss.mjs";
import error_handler_kEP5FliEXj from "E:/my_project/blog_nuxt/plugins/error-handler.ts";
export default [
  revive_payload_client_isoe4DKnXU,
  unhead_9SSIEpVLhs,
  router_T2iLcys8vd,
  payload_client_GPTp2QfHtT,
  navigation_repaint_client_fZ5HF6lhAk,
  check_outdated_build_client_fMrutYnnVP,
  chunk_reload_client_UblzgfJayh,
  plugin_vue3_pfZLgywtdQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5Tr9G6M6QT,
  plugin_oBpFtQ72gP,
  primevue_plugin_egKpok8Auk,
  plugin_client_m2wFZGtZZK,
  unocss_MzCDxu9LMj,
  error_handler_kEP5FliEXj
]
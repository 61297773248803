<template>
  <div id="head-content" class="head-content">
    <prime-toast position="top-center" class="font-LXGWWenKaiGB-serif" />
    <div>
      <prime-breadcrumb class="bg-transparent pl-0" :model="breadcrumb">
        <template #item="{ item }">
          <nuxt-link
            class="cursor-pointer font-LXGWWenKaiGB-serif color-fontPrimary"
            :href="item.url"
            @click.prevent="handleClick"
          >
            <MdiHomeOutline v-if="item.label === ''" class="c-[rgba(41,50,65,0.6)]" />
            <span v-else>{{ item.label }} </span>
          </nuxt-link>
        </template>
      </prime-breadcrumb>
    </div>
    <div class="flex-items-center box-border">
      <div class="flex-items-center box-border relative cursor-pointer" @click="onClickOpenAvatarPanel">
        <avatar :size="40" :src="userInfo.user.avatar"></avatar>
        <div class="ml-10px mr-5px max-lg:hidden">
          <div class="font-size-16px">{{ userInfo.user.name }}</div>
          <div class="c-fontGray font-size-13px mt-6px">管理员</div>
        </div>
        <MdiKeyboardArrowDown></MdiKeyboardArrowDown>
      </div>
    </div>
    <prime-overlay-panel ref="avatarPanel">
      <div class="grid grid-cols-[65px_65px_65px] gap-x-10px">
        <div
          v-for="item in avatarPanelList"
          :key="item.name"
          class="flex flex-col items-center box-border cursor-pointer hover:c-primary! transition-color-300"
          @click="onClickHandle(item.key)"
        >
          <div class="icon-style" :style="{ 'background-color': item.bgc }">
            <icons :name="item.icon" :style="{ color: item.iconColor }"></icons>
          </div>
          <span class="mt-5px font-size-14px font-LXGWWenKaiGB-serif">{{ item.name }}</span>
        </div>
      </div>
    </prime-overlay-panel>
  </div>
</template>

<script setup lang="ts">
import MdiHomeOutline from '~icons/mdi/home-outline';
import useAdminStore from '~/store/adminStore';
import MdiKeyboardArrowDown from '~icons/mdi/keyboard-arrow-down';
import { outLogin } from '~/http/user';
import { Avatar } from '#components';

const toast = useToast();
const store = useAdminStore();
const route = useRoute();
const avatarPanel = ref();
const { userInfo } = storeToRefs(store);

enum avatarPanelKey {
  accountInfo = 0,
  changePassword = 1,
  exit = 2,
}

const avatarPanelList = [
  {
    name: '账号信息',
    icon: 'mdi/MdiShieldUserOutline',
    iconColor: '#2196f3',
    bgc: '#CAE6FC',
    key: avatarPanelKey.accountInfo,
  },
  {
    name: '修改密码',
    icon: 'mdi/MdiPasswordReset',
    iconColor: '#dc3545',
    bgc: '#F8D7DA',
    key: avatarPanelKey.changePassword,
  },
  {
    name: '退出',
    icon: 'mdi/MdiExitToApp',
    iconColor: '#6c757d',
    bgc: '#F1F3F5',
    key: avatarPanelKey.exit,
  },
];

const onClickHandle = (key: number) => {
  switch (key) {
    case avatarPanelKey.accountInfo:
      break;
    case avatarPanelKey.changePassword:
      break;
    case avatarPanelKey.exit:
      onHandleExit();
      break;
  }
};

const onHandleExit = async () => {
  const result = await outLogin();
  if (result.data) {
    toast.add({ severity: 'success', summary: '退出成功', detail: '退出成功，跳转登录页', life: 1500 });
    window.localStorage.clear();
    setTimeout(() => {
      navigateTo('/admin/login');
    }, 1500);
  }
};

const handleClick = (event: any) => {
  if (event.target.href === '') event.preventDefault();
};
const onClickOpenAvatarPanel = (event: any) => {
  avatarPanel.value.toggle(event);
};

const getUrl = (index: number) => {
  if (index === 0) return '/admin/home';
  if (index === 1) return '';
  return route.path
    .split('/')
    .slice(0, index + 3)
    .join('/');
};

const breadcrumb = computed(() => {
  return (route.meta.breadcrumb as string)?.split('/').map((name, i) => {
    return {
      label: name,
      url: getUrl(i),
    };
  });
});
</script>

<style scoped lang="scss">
.head-content {
  box-sizing: border-box;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: var(--bgc-color);
}

.icon-style {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

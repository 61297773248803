import type { VNode } from 'vue';
import { cloneVNode, h, render } from 'vue';
import { ToastMessage } from '#components';

let vNode: VNode;
let clearNode: VNode;

const showToast = (message: string) => {
  if (!vNode) {
    vNode = h(ToastMessage, { message, show: true });
    clearNode = cloneVNode(vNode, { message, show: false });
  }
  const containerDom = document.getElementById('app-main')!;
  render(vNode, containerDom);
  setTimeout(() => {
    render(clearNode, containerDom);
    vNode = null; // 清理引用
  }, 2000);
};

export default showToast;

import { default as ArticleListiQouGbCjG3Meta } from "E:/my_project/blog_nuxt/pages/admin/Apps/article/ArticleList.vue?macro=true";
import { default as EditArticleRMClduLFb6Meta } from "E:/my_project/blog_nuxt/pages/admin/Apps/article/EditArticle.vue?macro=true";
import { default as TagListDpNf0zHgRwMeta } from "E:/my_project/blog_nuxt/pages/admin/Apps/article/TagList.vue?macro=true";
import { default as MenuManagM1A7AiE4uxMeta } from "E:/my_project/blog_nuxt/pages/admin/Apps/MenuManag.vue?macro=true";
import { default as indexYdwJKF55CHMeta } from "E:/my_project/blog_nuxt/pages/admin/home/index.vue?macro=true";
import { default as todoTask9DOUmE1m9IMeta } from "E:/my_project/blog_nuxt/pages/admin/home/todoTask.vue?macro=true";
import { default as QiniuResourceVJt2ltGrf1Meta } from "E:/my_project/blog_nuxt/pages/admin/Resource/QiniuResource.vue?macro=true";
import { default as EditSiteScheduledXs5BtFShGiMeta } from "E:/my_project/blog_nuxt/pages/admin/Site/EditSiteScheduled.vue?macro=true";
import { default as SiteScheduledvAyQNbwfUbMeta } from "E:/my_project/blog_nuxt/pages/admin/Site/SiteScheduled.vue?macro=true";
import { default as WebSetManageHwZ9tqmyZ9Meta } from "E:/my_project/blog_nuxt/pages/admin/Site/WebSetManage.vue?macro=true";
import { default as _91id_93ZdMJ2k9jRFMeta } from "E:/my_project/blog_nuxt/pages/article/[id].vue?macro=true";
import { default as index1RuXsPq7REMeta } from "E:/my_project/blog_nuxt/pages/box/webSet/index.vue?macro=true";
import { default as _91_91group_93_93q4aigKpBPHMeta } from "E:/my_project/blog_nuxt/pages/categories/[[group]].vue?macro=true";
import { default as indexEG1iHmUdIPMeta } from "E:/my_project/blog_nuxt/pages/dynamicLog/index.vue?macro=true";
import { default as indexZrhigSOiPrMeta } from "E:/my_project/blog_nuxt/pages/homePage/index.vue?macro=true";
import { default as indexk1ZNbVSTYCMeta } from "E:/my_project/blog_nuxt/pages/index.vue?macro=true";
import { default as indexnV0yetcWI1Meta } from "E:/my_project/blog_nuxt/pages/login/index.vue?macro=true";
import { default as indexJfrOnkBJ62Meta } from "E:/my_project/blog_nuxt/pages/oAuth/index.vue?macro=true";
import { default as SiteHistoryljUkXplb9bMeta } from "E:/my_project/blog_nuxt/pages/site/SiteHistory.vue?macro=true";
import { default as _91id_93F2IVcULkj6Meta } from "E:/my_project/blog_nuxt/pages/tag/[id].vue?macro=true";
export default [
  {
    name: "admin-Apps-article-ArticleList",
    path: "/admin/Apps/article/ArticleList",
    meta: ArticleListiQouGbCjG3Meta || {},
    component: () => import("E:/my_project/blog_nuxt/pages/admin/Apps/article/ArticleList.vue")
  },
  {
    name: "admin-Apps-article-EditArticle",
    path: "/admin/Apps/article/EditArticle",
    meta: EditArticleRMClduLFb6Meta || {},
    component: () => import("E:/my_project/blog_nuxt/pages/admin/Apps/article/EditArticle.vue")
  },
  {
    name: "admin-Apps-article-TagList",
    path: "/admin/Apps/article/TagList",
    meta: TagListDpNf0zHgRwMeta || {},
    component: () => import("E:/my_project/blog_nuxt/pages/admin/Apps/article/TagList.vue")
  },
  {
    name: "admin-Apps-MenuManag",
    path: "/admin/Apps/MenuManag",
    meta: MenuManagM1A7AiE4uxMeta || {},
    component: () => import("E:/my_project/blog_nuxt/pages/admin/Apps/MenuManag.vue")
  },
  {
    name: "admin-home",
    path: "/admin/home",
    meta: indexYdwJKF55CHMeta || {},
    component: () => import("E:/my_project/blog_nuxt/pages/admin/home/index.vue")
  },
  {
    name: "admin-home-todoTask",
    path: "/admin/home/todoTask",
    meta: todoTask9DOUmE1m9IMeta || {},
    component: () => import("E:/my_project/blog_nuxt/pages/admin/home/todoTask.vue")
  },
  {
    name: "admin-Resource-QiniuResource",
    path: "/admin/Resource/QiniuResource",
    meta: QiniuResourceVJt2ltGrf1Meta || {},
    component: () => import("E:/my_project/blog_nuxt/pages/admin/Resource/QiniuResource.vue")
  },
  {
    name: "admin-Site-EditSiteScheduled",
    path: "/admin/Site/EditSiteScheduled",
    meta: EditSiteScheduledXs5BtFShGiMeta || {},
    component: () => import("E:/my_project/blog_nuxt/pages/admin/Site/EditSiteScheduled.vue")
  },
  {
    name: "admin-Site-SiteScheduled",
    path: "/admin/Site/SiteScheduled",
    meta: SiteScheduledvAyQNbwfUbMeta || {},
    component: () => import("E:/my_project/blog_nuxt/pages/admin/Site/SiteScheduled.vue")
  },
  {
    name: "admin-Site-WebSetManage",
    path: "/admin/Site/WebSetManage",
    meta: WebSetManageHwZ9tqmyZ9Meta || {},
    component: () => import("E:/my_project/blog_nuxt/pages/admin/Site/WebSetManage.vue")
  },
  {
    name: "article-id",
    path: "/article/:id()",
    component: () => import("E:/my_project/blog_nuxt/pages/article/[id].vue")
  },
  {
    name: "box-webSet",
    path: "/box/webSet",
    component: () => import("E:/my_project/blog_nuxt/pages/box/webSet/index.vue")
  },
  {
    name: "categories-group",
    path: "/categories/:group?",
    component: () => import("E:/my_project/blog_nuxt/pages/categories/[[group]].vue")
  },
  {
    name: "dynamicLog",
    path: "/dynamicLog",
    component: () => import("E:/my_project/blog_nuxt/pages/dynamicLog/index.vue")
  },
  {
    name: "homePage",
    path: "/homePage",
    component: () => import("E:/my_project/blog_nuxt/pages/homePage/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("E:/my_project/blog_nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexnV0yetcWI1Meta || {},
    component: () => import("E:/my_project/blog_nuxt/pages/login/index.vue")
  },
  {
    name: "oAuth",
    path: "/oAuth",
    meta: indexJfrOnkBJ62Meta || {},
    component: () => import("E:/my_project/blog_nuxt/pages/oAuth/index.vue")
  },
  {
    name: "site-SiteHistory",
    path: "/site/SiteHistory",
    component: () => import("E:/my_project/blog_nuxt/pages/site/SiteHistory.vue")
  },
  {
    name: "tag-id",
    path: "/tag/:id()",
    component: () => import("E:/my_project/blog_nuxt/pages/tag/[id].vue")
  }
]
<template>
  <div id="app-main" class="box-border font-LXGWWenKaiGB-serif position-relative min-h-100vh bg-[var(--bgc-body)]">
    <layout-header></layout-header>
    <div :class="['default-content', isDark ? 'dark' : 'light']">
      <slot></slot>
      <quick-scroll></quick-scroll>
    </div>
    <layout-footer></layout-footer>
  </div>
</template>

<script setup lang="ts">
import AOS from 'aos';
import 'aos/dist/aos.css';
import { storeToRefs } from 'pinia';
import useStore from '~/store';

if (import.meta.client) {
  window._hmt = window._hmt || [];
}

useHead({
  script: [
    {
      src: import.meta.env.VITE_BAIDU_COUNT,
      defer: true,
      id: 'baidu-analytics',
    },
  ],
});

const store = useStore();
const { isDark } = storeToRefs(store);

if (import.meta.client) {
  requestAnimationFrame(() => {
    AOS.init({
      once: true,
      anchorPlacement: 'top-bottom',
    });
  });
}
</script>

<style scoped lang="scss">
.live2d {
  position: fixed;
  z-index: 9999;
  bottom: 0;
}

@media (max-width: 640px) {
  .default-content {
    min-height: calc(100vh - 226px) !important;
  }
}

.light {
  background-image: linear-gradient(90deg, #f0f0f0 3%, transparent 0), linear-gradient(1turn, #f0f0f0 3%, transparent 0);
  background-size: 20px 20px;
  background-position: 100% 100%;
}

.dark {
  background: var(--bgc-body);
}

.default-content {
  min-height: 100vh;
  box-sizing: border-box;
}
</style>

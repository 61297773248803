import { LocalStorageKey } from '~/const/sessionKey';

export default defineNuxtRouteMiddleware((to) => {
  let userInfo: IUser | null = null;
  const stringUser = window.localStorage.getItem(LocalStorageKey.userInfo);
  if (stringUser) userInfo = JSON.parse(stringUser);

  // 访问登录页面
  if (to.path === '/login') {
    if (userInfo) {
      if (new Date(userInfo.expiresAt) > new Date() && userInfo.token && userInfo?.user.role === 1) {
        return navigateTo('/admin/home');
      } else {
        window.localStorage.removeItem(LocalStorageKey.userInfo);
        userInfo = null;
      }
    }
    return;
  }
  // 处理其他 admin 页面，确保用户已经登录
  if (!userInfo || !userInfo.token || new Date(userInfo.expiresAt) <= new Date() || userInfo?.user.role === 2) {
    window.localStorage.removeItem(LocalStorageKey.userInfo);
    userInfo = null;
    return navigateTo('/login');
  }
});

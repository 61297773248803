import type { PropType } from 'vue';
import styles from './css/menu.module.scss';
import MenuItem from './MenuItem';
import IconHouse from '~icons/noto/house-with-garden';
import type { IMenuItemDto } from '~/http/types/menuItem';

export default defineComponent({
  name: 'MenuComponent',
  props: {
    isScroll: {
      type: Boolean,
      default: false,
    },
    menu: {
      type: Array as PropType<IMenuItemDto[]>,
      default: () => [],
    },
    iconEnum: {
      type: Object,
      default: () => {},
    },
  },
  setup(props: { isScroll: boolean; menu: IMenuItemDto[] }) {
    const onClickToPath = (path = '/') => navigateTo(path);

    return () => {
      return (
        <ul class={styles.menuContent}>
          <li class={styles.menuLi}>
            <div class={`flex-items-center ${styles.menuHome}`} onClick={() => onClickToPath()}>
              <IconHouse />
              <span class={props.isScroll ? '!c-fontLight' : ''}>首页</span>
            </div>
          </li>
          {props.menu.map((item) => {
            return <MenuItem isScroll={props.isScroll} key={item.id} item={item} />;
          })}
        </ul>
      );
    };
  },
});

<template>
  <transition name="out-top">
    <div v-show="props.show" id="toast" class="toast-content bg-primary flex-center c-#fff font-size-20px">
      {{ props.message }}
    </div>
  </transition>
</template>
<script setup lang="ts">
const props = defineProps<{ message?: string; show: boolean }>();
</script>
<style scoped lang="scss">
@keyframes slide-out-bottom {
  0% {
    transform: translateY(-65px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out-top {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-65px);
    opacity: 0;
  }
}

@keyframes rightWight {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.out-top-leave-active {
  animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both !important;
}

.toast-content {
  box-sizing: border-box;
  width: 100%;
  height: 65px;
  animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    animation: rightWight 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation-delay: 0.5s;
    z-index: 1;
  }
}
</style>

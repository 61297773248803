<script setup lang="ts">
import IconSet from '~/const/iconsSet';

interface BaseIconProps {
  name: keyof typeof IconSet;
}

const props = withDefaults(defineProps<BaseIconProps>(), {
  name: '',
});
</script>

<template>
  <suspense>
    <component :is="IconSet[props.name] || 'span'" v-bind="$attrs" />
  </suspense>
</template>

<style scoped lang="scss"></style>

<template>
  <div id="app-main" class="position-relative w-100% h-100vh box-border bg-[var(--bgc-body)] font-LXGWWenKaiGB-serif">
    <div class="flex w-100% h-100% box-border">
      <div
        class="sidebar bg-[var(--bgc-color)] h-100% max-lg:hidden"
        @mouseleave.stop="isSidebarOpen = false"
        @mouseenter.stop="isSidebarOpen = true"
      >
        <private-admin-menu :is-sidebar-open="isSidebarOpen"></private-admin-menu>
      </div>
      <div class="admin-container max-lg:w-100%!">
        <private-admin-head></private-admin-head>
        <div
          class="h-[calc(100%-60px)] box-border w-100% rounded-tl-30px max-lg:rounded-tl-0 main-content p-20px bg-[--bgc-body] overflow-y-auto"
        >
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const isSidebarOpen = ref(true);
const sidebarWidth = computed(() => (isSidebarOpen.value ? '175px' : '60px'));
</script>

<style scoped lang="scss">
@media (max-width: 1200px) {
  .main-content {
    padding: 10px;
  }
}

.sidebar {
  width: v-bind(sidebarWidth);
  border-right: var(--card-borderColor);
  box-shadow: 0px 10px 40px rgba(41, 50, 65, 0.06);
  box-sizing: border-box;
  transition: width 0.5s ease;
  @include fade-in-Animation;
}

.admin-container {
  background-color: var(--bgc-color);
  border-right: var(--card-borderColor);
  width: calc(100% - v-bind(sidebarWidth));
  transition: width 0.5s ease;
  flex-shrink: 0;
  box-sizing: border-box;
  @include fade-in-Animation;
}
</style>

import type { NitroFetchOptions } from 'nitropack';
import useAdminStore from '~/store/adminStore';

const baseUrl = import.meta.env.VITE_API_SECRET;

let token: string;

const fetchHttp = <T>(url: string, options: NitroFetchOptions<string>, isToken = true) => {
  if (isToken) {
    if (!token) {
      const { $pinia } = useNuxtApp();
      const store = useAdminStore($pinia);
      token = store.userInfo.token;
    }
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return $fetch<Response<T>>(url, {
    ...options,
    baseURL: baseUrl,
    retry: 0,
  });
};

export default fetchHttp;

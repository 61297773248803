import fetchHttp from '~/http/fetchHttp';

const getEmailCode = (params: { sendEmailNumber: string; name: string }) => {
  return fetchHttp<boolean>(
    'email/sendRegisterEmail',
    {
      method: 'POST',
      body: params,
    },
    false,
  );
};

const validateRegisterCode = (params: { emailNumber: string; code: number }) => {
  return fetchHttp<boolean>(
    'email/validateRegisterCode',
    {
      method: 'POST',
      body: params,
    },
    false,
  );
};

// 注册账号
const createUser = (params: { name: string; email: string; pwd: string; avatar?: string }) => {
  return fetchHttp<boolean>(
    'user/createUser',
    {
      method: 'POST',
      body: params,
    },
    false,
  );
};

// 登录账号
const login = (params: { email: string; pwd: string }) => {
  return fetchHttp<IUser>(
    'user/login',
    {
      method: 'POST',
      body: params,
    },
    false,
  );
};

//  退出登录
const outLogin = () => {
  return fetchHttp<Boolean>('user/outLogin', {
    method: 'POST',
  });
};

// 登录账号
const githubLogin = (params: { code: string }) => {
  return fetchHttp<IUser>(
    'user/gitHubLogin',
    {
      method: 'POST',
      body: params,
    },
    false,
  );
};

export { getEmailCode, validateRegisterCode, createUser, login, outLogin, githubLogin };

import type { IMotivationDto } from '~/http/types/motivation';
import useHttpFetch from '~/composables/useHttpFetch';

const useOneMotivation = (id?: number) => {
  return useHttpFetch<IMotivationDto>('motivation/getOneMotivation', {
    method: 'get',
    params: { id },
  });
};

export { useOneMotivation };

import type { AsyncData, UseFetchOptions } from '#app';

const baseUrl = import.meta.env.VITE_API_SECRET;

const useHttpFetch = <T = boolean>(url: string, options: UseFetchOptions<Response<T>>) => {
  options.baseURL = baseUrl;
  return useFetch<Response<T>>(url, options) as unknown as AsyncData<Response<T>, ErrorResponse<null>>;
};

export default useHttpFetch;

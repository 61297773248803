<template>
  <div>
    <div class="min-h-100vh page-home-bgc">
      <div class="typing-box">
        <chevron-down class="icon-down" @click="onScrollTo"></chevron-down>
        <div class="p-10px relative bg-[rgba(36,36,36,0.5)] rounded-8px">
          <div class="typing">抱怨身处黑暗，不如提灯前行</div>
        </div>
      </div>
    </div>
    <div class="main-container pt-10px">
      <motivation-card></motivation-card>
      <div class="main-content lg:flex mt-10px w-inherit box-border">
        <div
          class="w-[calc(100%-290px)] home-content max-lg:w-[100%] lg:pr-20px max-xl:!grid-cols-[100%] transition-all-500"
        >
          <article-crad v-for="item in list" :key="item?.id" data-aos="fade-up" :article-dto="item"></article-crad>
          <!--          <div class="flex-center">-->
          <!--            <button v-if="isPullRefresh" class="addArticleBtn" @click="addArticle">查看更多</button>-->
          <!--            <a-divider v-else class="font-LXGWWenKaiGB-serif !c-fontPrimary">没有更多啦！</a-divider>-->
          <!--          </div>-->
        </div>
        <div class="w-290px home-side max-lg:!hidden">
          <author-info data-aos="fade-up"></author-info>
          <weibo-hot data-aos="fade-up" class="mt-20px"></weibo-hot>
          <hot-article data-aos="fade-up" class="mt-20px"></hot-article>
          <tag-set data-aos="fade-up" class="mt-20px"></tag-set>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useArticleList } from '~/http/article';
import ChevronDown from '~icons/mdi/chevron-down';
import { AuthorInfo } from '#components';
import { ArticleEnum } from '~/const/public';

useHead({
  title: `Dream博客 - 首页`,
  meta: [
    {
      name: 'description',
      content:
        'Dream-Blog,这是我的个人博客网站，记录解决生活工作的问题，记录自己的生活，它是一个 Nest + Nuxt3 + TypeScript 的产物。',
    },
  ],
});
const listParams = {
  pageSize: 10,
  pageNum: 1,
  state: ArticleEnum.issue,
};

const { data: request } = await useArticleList('home', listParams);

const list = ref(unref(request)?.data.list);

const onScrollTo = () => {
  window.scrollTo({
    top: window.innerHeight,
    behavior: 'smooth',
  });
};
</script>

<style scoped lang="scss">
.addArticleBtn {
  background: none;
  border: 2px solid;
  font: inherit;
  line-height: 1;
  padding: 10px;
  border-radius: 8px;
  color: var(--colorPrimary);
  transition: 0.25s;
  cursor: pointer;

  &:hover,
  &:focus {
    animation: pulse 1s;
    box-shadow: 0 0 0 2em transparent;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 var(--colorPrimary);
    }
  }
}

.typing-box {
  position: absolute;
  width: 100%;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.typing {
  position: relative;
  color: var(--font-light);
  font-weight: 500;
  font-size: 25px;
  border-right: 0.1em solid var(--font-light);
  width: 13em;
  white-space: nowrap;
  overflow: hidden;
  animation:
    typing 3s steps(13, end),
    blink-caret 0.5s step-end infinite alternate;

  @keyframes typing {
    from {
      width: 0;
    }
  }
  @keyframes blink-caret {
    50% {
      border-color: transparent;
    }
  }
}

.icon-down {
  position: absolute;
  bottom: 10px;
  color: var(--font-light);
  font-size: 45px;
  cursor: pointer;
  animation: jump 2s linear 0s infinite normal none;
  @keyframes jump {
    0%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px) scale(1.25);
    }
    50% {
      transform: translateY(10px);
    }
  }

}

.page-home-bgc {
  position: relative;
  background-image: url('https://cdnfile.5chenz.com/img/homePage.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  animation: slide-in 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @keyframes slide-in {
    0% {
      transform: translateY(-100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.home-content {
  flex: 0 0 auto;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-content: start;
}

.home-side {
  box-sizing: border-box;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}
</style>

const IconSet = {
  'mdi/MdiExitToApp': defineAsyncComponent(() => import('~icons/mdi/exit-to-app')),
  'mdi/MdiKeyboardArrowDown': defineAsyncComponent(() => import('~icons/mdi/keyboard-arrow-down')),
  'mdi/MdiPasswordReset': defineAsyncComponent(() => import('~icons/mdi/password-reset')),
  'mdi/MdiShieldUserOutline': defineAsyncComponent(() => import('~icons/mdi/shield-user-outline')),
  'mdi/MdiLanguageHtml': defineAsyncComponent(() => import('~icons/mdi/language-html5')),
  'mdi/MdiServerSecurity': defineAsyncComponent(() => import('~icons/mdi/server-security')),
  'mdi/MdiMicrosoftWindows': defineAsyncComponent(() => import('~icons/mdi/microsoft-windows')),
  'mdi/MdiWebBox': defineAsyncComponent(() => import('~icons/mdi/web-box')),
  'noto/NotoBooks': defineAsyncComponent(() => import('~icons/noto/books')),
  'noto/NotoPen': defineAsyncComponent(() => import('~icons/noto/pen')),
  'noto/NotoToolbox': defineAsyncComponent(() => import('~icons/noto/toolbox')),
  'mdi/mdiFileCloudOutline': defineAsyncComponent(() => import('~icons/mdi/file-cloud-outline')),
  'mdi/mdiWidgetBellCurveCumulative': defineAsyncComponent(() => import('~icons/mdi/widget-bell-curve-cumulative')),
  'mdi/mdiWifiArrowUpDown': defineAsyncComponent(() => import('~icons/mdi/wifi-arrow-up-down')),
  'mdi/mdiCloudOutline': defineAsyncComponent(() => import('~icons/mdi/cloud-outline')),
  'noto/NotoFileFolder': defineAsyncComponent(() => import('~icons/noto/file-folder')),
  'mySvg/picture': defineAsyncComponent(() => import('~icons/mySvg/picture')),
  'mySvg/fileTypeJson': defineAsyncComponent(() => import('~icons/mySvg/fileType-json')),
  'mdi/mdiFontSize': defineAsyncComponent(() => import('~icons/mdi/font-size')),
  'mdi/mdiNodejs': defineAsyncComponent(() => import('~icons/mdi/nodejs')),
  'mdi/mdiHomeOutline': defineAsyncComponent(() => import('~icons/mdi/home-outline')),
  'mdi/mdiTaskAdd': defineAsyncComponent(() => import('~icons/mdi/task-add')),
  'mdi/mdiBookOpenVariantOutline': defineAsyncComponent(() => import('~icons/mdi/book-open-variant-outline')),
  'mdi/mdiFormatListBulleted': defineAsyncComponent(() => import('~icons/mdi/format-list-bulleted')),
  'mdi/mdiFolderFileOutline': defineAsyncComponent(() => import('~icons/mdi/folder-file-outline')),
  'mdi/mdiTagTextOutline': defineAsyncComponent(() => import('~icons/mdi/tag-text-outline')),
  'mdi/mdiPen': defineAsyncComponent(() => import('~icons/mdi/pen')),
  'mdi/mdiMenu': defineAsyncComponent(() => import('~icons/mdi/menu')),
  'mdi/mdiScheduledMaintenance': defineAsyncComponent(() => import('~icons/mdi/scheduled-maintenance')),
  'mdi/mdiSquareEditOutline': defineAsyncComponent(() => import('~icons/mdi/square-edit-outline')),
  'noto/NotoFourLeafClover': defineAsyncComponent(() => import('~icons/noto/four-leaf-clover')),
  'mdi/mdiWeatherHistory': defineAsyncComponent(() => import('~icons/mdi/weather-history')),
  'mdi/mdiRefresh': defineAsyncComponent(() => import('~icons/mdi/refresh')),
  'mdi/shareVariantOutline': defineAsyncComponent(() => import('~icons/mdi/share-variant-outline')),
  'mdi/mdiWeb': defineAsyncComponent(() => import('~icons/mdi/web')),
} as Record<string, ReturnType<typeof defineAsyncComponent>>;

export default IconSet;

import type { PropType } from 'vue';
import { Transition } from 'vue';
import styles from './css/menu.module.scss';
import { ClientOnly, Icons } from '#components';
import type { IMenuItemDto } from '~/http/types/menuItem';

export default defineComponent({
  name: 'MenuItem',
  props: {
    isScroll: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object as PropType<IMenuItemDto>,
      default: () => {},
    },
  },

  setup(props: { isScroll: boolean; item: IMenuItemDto }) {
    const hoverMenu = ref(false);
    const visible = ref(false);
    let timer: any = null;

    const onMouseenter = () => {
      clearTimeout(timer);
      visible.value = true;
    };
    const onMouseleave = () => {
      timer = setTimeout(() => {
        visible.value = false;
      }, 250);
    };

    const onClickToPath = (path = '/', id?: string) => {
      path.includes('categories') ? navigateTo(`${path}${id}`) : navigateTo(path);
      visible.value = false;
    };
    const onClickParent = () => {
      if (props.item.route === '') return;
      onClickToPath(`${props.item.route}`);
    };
    return () => {
      return (
        <li class={styles.menuLi} onClick={onClickParent}>
          <div
            class={`flex-items-center ${styles.menuContainer}`}
            onMouseenter={onMouseenter}
            onMouseleave={onMouseleave}
          >
            <Icons name={props.item.icon}></Icons>
            <span class={`${hoverMenu.value ? styles.hoverColor : ''} ${props.isScroll ? '!c-fontLight' : ''}`}>
              {props.item.name}
            </span>
          </div>
          {props.item.children && props.item.children.length > 0 && (
            <ClientOnly>
              <Transition
                enterFromClass={styles.fadeEnterFrom}
                leaveToClass={styles.fadeLeaveTo}
                enterActiveClass={styles.fadeEnterActive}
                leaveActiveClass={styles.fadeLeaveActive}
                mode="out-in"
              >
                <div
                  v-show={visible.value}
                  onMouseenter={onMouseenter}
                  onMouseleave={onMouseleave}
                  id={`menuId${props.item.id}`}
                  class={`${styles.popoverMain}  bg-[var(--bgc-box)] p-8px`}
                >
                  {props.item.children?.map((item) => (
                    <div class={styles.menuItem} onClick={() => onClickToPath(item.route, item.id)}>
                      <Icons name={item.icon}></Icons>
                      <span class="c-fontPrimary text-15px ml-5px whitespace-nowrap">{item.name}</span>
                    </div>
                  ))}
                </div>
              </Transition>
            </ClientOnly>
          )}
        </li>
      );
    };
  },
});

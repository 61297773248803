
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/zh-cn'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'


dayjs.extend(updateLocale)

dayjs.extend(relativeTime)
dayjs.extend(utc)



// defaultLocale: "zh-cn"


dayjs.updateLocale("zh-cn")
dayjs.locale('zh-cn')


export default dayjs

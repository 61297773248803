<template>
  <div class="w-100% h-100vh flex-center flex-col">
    <img src="/404.png" alt="404" class="w-40% h-70%" />
    <div class="error-box">
      <a class="error-bgc" href="/">Back</a>
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  error: Object,
});

console.log(props.error);
</script>

<style scoped lang="scss">
@keyframes color-change-5x {
  0% {
    background: #19dcea;
  }
  25% {
    background: #b22cff;
  }
  50% {
    background: #ea2222;
  }
  75% {
    background: #f5be10;
  }
  100% {
    background: #3bd80d;
  }
}

.error-box {
  @keyframes shake-bottom {
    0%,
    100% {
      transform: rotate(0deg);
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
    }
    10% {
      transform: rotate(2deg);
    }
    20%,
    40%,
    60% {
      transform: rotate(-4deg);
    }
    30%,
    50%,
    70% {
      transform: rotate(4deg);
    }
    80% {
      transform: rotate(-2deg);
    }
    90% {
      transform: rotate(2deg);
    }
  }

  &:hover {
    animation: shake-bottom 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  }
}

.error-bgc {
  display: inherit;
  width: 100px;
  height: 45px;
  border-radius: 10px;
  color: white;
  font-weight: 600;
  text-align: center;
  line-height: 45px;
  text-decoration: none;
  font-size: 20px;
  animation: color-change-5x 8s linear infinite alternate both;
}
</style>

<template>
  <div>
    <div class="cardStyle h-360px transition-all-500! max-md:h-320px">
      <NuxtLink class="link-style" :to="`/article/${articleDto.id}`">
        <div class="transition-inherit overflow-hidden rounded-[8px] img-content w-[100%] h-[190px] max-md:h-150px">
          <nuxt-img
            :src="articleDto.cover"
            class="article-cover w-[100%] h-[100%] object-cover rounded-[8px] transition-all-500 hover:cursor-pointer"
            alt="cover"
            loading="lazy"
          />
        </div>
      </NuxtLink>
      <div class="box-border article-content h-[calc(100%-190px)] max-md:h-[calc(100%-150px)]">
        <nuxt-link :to="`/article/${articleDto.id}`">
          <span class="article-title transition-color-500 c-fontPrimary font-900 font-size-18px">{{
            articleDto.title
          }}</span>
        </nuxt-link>
        <div class="my-15px info-content flex items-center">
          <div class="article-info">
            <client-only>
              <icon-spiral-calendar></icon-spiral-calendar>
            </client-only>
            <span>{{ dayjs(articleDto.createAt).format('YYYY-MM-DD') }}</span>
          </div>
          <div class="article-info">
            <client-only>
              <icon-fire></icon-fire>
            </client-only>
            <span>{{ articleDto.views.toLocaleString() ?? 0 }}热度</span>
          </div>
          <div class="article-info">
            <client-only>
              <icon-heart-suit></icon-heart-suit>
            </client-only>
            <span>{{ articleDto.like }}赞</span>
          </div>
        </div>
        <div class="text-content max-xl:!font-size-13px">
          {{ removeFilteredCharacters(articleDto.content, filterString) }}
        </div>
        <div class="flex justify-between mt-15px">
          <Tag :to="`/categories/${articleDto.menuItem.id}`" :name="articleDto.menuItem.name">
            <template #icon>
              <icon-open-file-folder class="font-size-13px transition-color-300"></icon-open-file-folder>
            </template>
          </Tag>
          <div class="tags">
            <Tag
              v-for="item in articleDto.tags"
              :key="item.id"
              :to="`/tag/${item.id}`"
              :name="item.name"
              class="mr-5px"
            ></Tag>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="tsx">
import type { IArticleDto } from '~/http/types/article';
import IconFire from '~icons/noto/fire';
import IconHeartSuit from '~icons/noto/heart-suit';
import IconOpenFileFolder from '~icons/noto/open-file-folder';
import IconSpiralCalendar from '~icons/noto/spiral-calendar';
import { removeFilteredCharacters } from '~/utils';

const dayjs = useDayjs();
const filterString = ['*', '`', '[', ']', '>', '#'];

const { articleDto } = defineProps<{ articleDto: IArticleDto }>();
</script>

<style scoped lang="scss">
@media (min-width: 640px) {
  .cardStyle {
    &:hover {
      box-shadow: var(--hover-card-shadow);
    }
  }
}

@media (max-width: 1280px) {
  .article-info {
    svg {
      font-size: 10px !important;
    }

    & > span {
      font-size: 12px !important;
    }
  }
}

@media (max-width: 1280px) and (min-width: 640px) {
  .cardStyle {
    display: flex;
    height: 160px;
  }
  .link-style {
    flex: 0 0 auto;
    width: 270px;

    & > div {
      height: 157px;
    }
  }
  .article-content {
    height: 100%;
  }
  .article-title {
    font-size: 17px;
  }
  .info-content {
    margin: 13px 0;
  }
}

@keyframes slide-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.bottomShow {
  animation: slide-in-bottom 1s ease both;
}

.info-content > .article-info:not(:last-child) {
  &::before {
    position: absolute;
    content: '';
    width: 4px;
    height: 4px;
    background-color: var(--font-gray);
    border-radius: 50%;
    right: -12px;
  }
}

.article-info {
  display: flex;
  align-items: center;
  margin-right: 20px;
  position: relative;

  svg {
    font-size: 11px;
  }

  & > span {
    color: var(--font-gray);
    font-weight: 600;
    font-size: 14px;
    margin-left: 5px;
  }
}

.article-title {
  width: 100%;
  word-break: break-all;
  @include textManyEllipsis(1);
}

.article-content {
  padding: 20px 20px 0 20px;
}

.text-content {
  box-sizing: border-box;
  color: var(--font-gray);
  font-size: 14px;
  word-break: break-all;
  @include textManyEllipsis(2);
}

.article-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardStyle {
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  @include cardContent;

  &:hover {
    .article-cover {
      transform: scale(1.1);
    }

    .article-title {
      color: var(--colorPrimary);
    }
  }
}

.tags {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>

const useThrottleEventListener = <T extends string>(
  eventName: T,
  throttleTime = 500,
  target: (event: Event) => void,
  options: {
    eventListenerTarget: EventTarget;
    eventOptions: AddEventListenerOptions;
  } = {
    eventListenerTarget: window,
    eventOptions: { passive: true },
  },
) => {
  const throttleEventFn = lodashThrottle((event: Event) => {
    target(event);
    event.stopPropagation();
  }, throttleTime);

  const removeEventListener = () => {
    options.eventListenerTarget.removeEventListener(eventName, throttleEventFn, options.eventOptions);
  };

  onMounted(() => {
    options.eventListenerTarget.addEventListener(eventName, throttleEventFn, options.eventOptions);
  });

  onUnmounted(() => {
    removeEventListener();
  });

  return { removeEventListener };
};

export default useThrottleEventListener;

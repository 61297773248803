<script setup lang="ts">
import type { StyleValue } from 'vue';

type Props = {
  size?: number;
  contentStyle?: StyleValue;
  src?: string;
  isActive?: boolean;
  label?: string;
};
const props = withDefaults(defineProps<Props>(), {
  size: 70,
  contentStyle: () => {
    return {};
  },
  src: getCdnPath('img/avatar.png'),
  isActive: true,
  label: '',
});

const activeClass = 'cursor-pointer hover:rotate-360 rounded-50% transition-all-300 )';
const avatarSize = computed(() => {
  return {
    width: `${props.size}px`,
    height: `${props.size}px`,
  };
});
const beforeLabel = computed(() => props.label.slice(0, 1));
</script>
<template>
  <div class="avatar transition-top,width,height-300" :style="props.contentStyle">
    <img v-if="props.isActive" :src="props.src" :style="avatarSize" :class="activeClass" alt="avatar" />
    <div v-else :style="avatarSize" :class="activeClass">
      {{ beforeLabel }}
    </div>
  </div>
</template>

<style scoped lang="scss">
@keyframes shadow-Animation {
  0% {
    box-shadow: 0 0 1px 1px var(--colorPrimary);
  }
  50% {
    box-shadow: 0 0 3px 3px var(--colorPrimary);
  }
  100% {
    box-shadow: 0 0 1px 1px var(--colorPrimary);
  }
}

.avatar {
  text-align: center;

  img {
    animation: shadow-Animation 3s ease-in-out infinite;
  }
  div {
    color: var(--colorPrimary);
    font-weight: 600;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    animation: shadow-Animation 3s ease-in-out infinite;
  }
}
</style>

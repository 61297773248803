import { defineStore } from 'pinia';
import type { IArticleTagTypeCount } from '~/http/types/article';
import type { IMenuItemDto } from '~/http/types/menuItem';

const useStore = defineStore('store', () => {
  const isDark = ref(false);
  const articleTagTypeCount = reactive({
    articleCount: 0,
    tagCount: 0,
    typeCount: 0,
  });
  const flatMenu = ref([] as Array<IMenuItemDto>);
  const setDark = (dark: boolean) => {
    isDark.value = dark;
  };

  const setFlatMenu = (list: Array<IMenuItemDto>) => {
    flatMenu.value = list;
  };
  const setArticleTagTypeCount = (data: IArticleTagTypeCount) => Object.assign(articleTagTypeCount, data);
  return { isDark, setDark, articleTagTypeCount, setArticleTagTypeCount, setFlatMenu, flatMenu };
});

export default useStore;

const route = [
  { name: 'DASHBOARDS', isTitle: true },
  {
    name: '首页',
    icon: 'mdi/mdiHomeOutline',
    route: '/admin/home',
  },
  {
    name: '待办事项',
    icon: 'mdi/mdiTaskAdd',
    route: '/admin/home/todoTask',
  },
  { name: 'Apps', isTitle: true },
  {
    name: '文章相关',
    icon: 'mdi/mdiBookOpenVariantOutline',
    route: '/admin/Apps/article',
    children: [
      {
        name: '文章列表',
        icon: 'mdi/mdiFormatListBulleted',
        route: '/admin/Apps/article/ArticleList',
      },
      {
        name: '编写文章',
        icon: 'mdi/mdiPen',
        route: '/admin/Apps/article/EditArticle',
      },
      {
        name: '标签列表',
        icon: 'mdi/mdiTagTextOutline',
        route: '/admin/Apps/article/TagList',
      },
    ],
  },
  {
    name: '菜单管理',
    icon: 'mdi/mdiMenu',
    route: '/admin/Apps/MenuManag',
  },

  { name: 'Resource', isTitle: true },
  {
    name: '七牛云存储',
    icon: 'mdi/mdiFolderFileOutline',
    route: '/admin/Resource/QiniuResource',
  },

  { name: 'Site', isTitle: true },
  {
    name: '建设日志',
    icon: 'mdi/mdiScheduledMaintenance',
    route: '/admin/Site/SiteScheduled',
  },
  {
    name: '编写建设日志',
    icon: 'mdi/mdiSquareEditOutline',
    route: '/admin/Site/EditSiteScheduled',
  },
  {
    name: '站点导航管理',
    icon: 'mdi/mdiWeb',
    route: '/admin/Site/WebSetManage',
  },
];

export default route;

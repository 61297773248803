<template>
  <module-card title="Hot">
    <template #icon>
      <client-only>
        <icon-fire class="font-size-12px mr-5px"></icon-fire>
      </client-only>
    </template>
    <div v-for="item in request?.data.list" :key="item.id" class="hot-article-item">
      <div class="flex">
        <nuxt-link
          class="w-[90px] h-[65px] transition-inherit flex-no-auto flex-center overflow-hidden rounded-[8px]"
          :to="`/article/${item.id}`"
        >
          <nuxt-img
            :src="item.cover"
            class="h-[100%] w-[100%] rounded-[8px] object-cover transition-transform-300 hover:cursor-pointer hover:scale-105"
            alt="cover"
            loading="lazy"
          />
        </nuxt-link>
        <div class="font-size-13px pl-10px pb-3px flex flex-col justify-between box-border w-[calc(100%-70px)]">
          <nuxt-link class="article-title" :to="`/article/${item.id}`">{{ item.title }}</nuxt-link>
          <div class="flex-items-center justify-between">
            <span class="flex-items-center c-fontGray">
              <client-only>
                <watch-loop class="font-size-14px"></watch-loop>
              </client-only>
              <span class="font-size-12px p-l-3px"> {{ item.views?.toLocaleString() ?? 0 }}次预览</span>
            </span>
            <client-only>
              <coffee-loop class="c-fontGray"></coffee-loop>
            </client-only>
          </div>
        </div>
      </div>
    </div>
  </module-card>
</template>

<script setup lang="ts">
import { useArticleList } from '~/http/article';
import CoffeeLoop from '~icons/line-md/coffee-loop';
import WatchLoop from '~icons/line-md/watch-loop';
import IconFire from '~icons/noto/fire';
import { ArticleEnum } from '~/const/public';

const { data: request } = await useArticleList('hot', {
  pageSize: 5,
  pageNum: 1,
  orderByName: 'views',
  state: ArticleEnum.issue,
});
</script>

<style scoped lang="scss">
.hot-article-item {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 10px;
  align-content: start;

  .article-title {
    cursor: pointer;
    color: var(--font-color);
    transition: color 300ms ease;
    @include textManyEllipsis;
    height: 31px;
    word-break: break-all;

    &:hover {
      color: var(--colorPrimary);
    }
  }
}
</style>

import { defineNuxtPlugin } from '#app/nuxt'
import { LazyPrimeCalendar, LazyPrimeDropdown, LazyPrimeFloatLabel, LazyPrimeInputGroup, LazyPrimeInputGroupAddon, LazyPrimeInputNumber, LazyPrimeInputText, LazyPrimeMultiSelect, LazyPrimePassword, LazyPrimeTextarea, LazyPrimeButton, LazyPrimeSpeedDial, LazyPrimeColumn, LazyPrimeDataTable, LazyPrimeTreeTable, LazyPrimeTimeline, LazyPrimeCard, LazyPrimeConfirmDialog, LazyPrimeDialog, LazyPrimeOverlayPanel, LazyPrimeBreadcrumb, LazyPrimeInlineMessage, LazyPrimeToast, LazyPrimeImage, LazyPrimeProgressBar, LazyPrimeProgressSpinner, LazyPrimeTag } from '#components'
const lazyGlobalComponents = [
  ["PrimeCalendar", LazyPrimeCalendar],
["PrimeDropdown", LazyPrimeDropdown],
["PrimeFloatLabel", LazyPrimeFloatLabel],
["PrimeInputGroup", LazyPrimeInputGroup],
["PrimeInputGroupAddon", LazyPrimeInputGroupAddon],
["PrimeInputNumber", LazyPrimeInputNumber],
["PrimeInputText", LazyPrimeInputText],
["PrimeMultiSelect", LazyPrimeMultiSelect],
["PrimePassword", LazyPrimePassword],
["PrimeTextarea", LazyPrimeTextarea],
["PrimeButton", LazyPrimeButton],
["PrimeSpeedDial", LazyPrimeSpeedDial],
["PrimeColumn", LazyPrimeColumn],
["PrimeDataTable", LazyPrimeDataTable],
["PrimeTreeTable", LazyPrimeTreeTable],
["PrimeTimeline", LazyPrimeTimeline],
["PrimeCard", LazyPrimeCard],
["PrimeConfirmDialog", LazyPrimeConfirmDialog],
["PrimeDialog", LazyPrimeDialog],
["PrimeOverlayPanel", LazyPrimeOverlayPanel],
["PrimeBreadcrumb", LazyPrimeBreadcrumb],
["PrimeInlineMessage", LazyPrimeInlineMessage],
["PrimeToast", LazyPrimeToast],
["PrimeImage", LazyPrimeImage],
["PrimeProgressBar", LazyPrimeProgressBar],
["PrimeProgressSpinner", LazyPrimeProgressSpinner],
["PrimeTag", LazyPrimeTag],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

<template>
  <div
    ref="headerRef"
    class="header-content"
    :class="[isScroll ? '' : 'headerBgc', flagScrollPull ? 'header-exits' : 'header-entrances']"
  >
    <div class="flex justify-between box-border sm:px-3% items-center h-[65px]">
      <nuxt-link class="color-fontPrimary flex-items-center cursor-pointer flex-no-auto" to="/">
        <span class="pr-2 font-title web-title" :class="[isScroll ? 'c-fontLight' : '']">Dream-Life</span>
        <TitleSvg class="icons-title"></TitleSvg>
      </nuxt-link>
      <div class="flex h-[65px] items-center flex-no-auto">
        <div id="titlePrimaryDom" class="flex h-inherit items-center header-none w-[100%]">
          <Menu :menu="request.data" :is-scroll="isScroll"></Menu>
        </div>
        <theme-switch></theme-switch>
        <icon-magnify
          :class="[isScroll ? '!c-fontLight' : '']"
          class="font-size-18px flex-shrink-0 hover:scale-115 hover:text-primary hover:cursor-pointer ml-2 color-fontPrimary transition-transform duration-300"
        ></icon-magnify>
        <form-list-bulleted
          class="font-size-18px flex-shrink-0 hover:scale-115 hover:text-primary hover:cursor-pointer ml-2 color-fontPrimary transition-transform duration-300 sm:!hidden"
          :class="[isScroll ? '!c-fontLight' : '']"
          @click="onOpenMenu"
        ></form-list-bulleted>
        <Drawer v-model:open="isMenu" content-class="sm:!hidden" :width="280">
          <sm-menu :menu="request.data" @close="onOpenMenu"></sm-menu>
        </Drawer>
        <div class="ml-20px" @click="onClickAvatar">
          <avatar :size="40" :src="avatarRef"></avatar>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SmMenu } from '#components';
import FormListBulleted from '~icons/mdi/format-list-bulleted';
import IconMagnify from '~icons/mdi/magnify';
import { useMenuItemList } from '~/http/menuItem';
import useStore from '~/store/index';
import type { IMenuItemDto } from '~/http/types/menuItem';
import TitleSvg from '~icons/mySvg/titleLogo';
import { LocalStorageKey } from '~/const/sessionKey';

const avatarRef = ref(getCdnPath('img/avatar.png'));
const headerRef = ref<HTMLElement>();
const flagScrollPull = ref(false);
const isScroll = ref(true);
let oldScrollY = process.client ? window.scrollY : 0;
const isMenu = ref(true);
const store = useStore();

const { data: request } = await useMenuItemList(true);
const handleScrollPull = (latestY: number) => {
  const isPull = oldScrollY < latestY;
  oldScrollY = latestY;
  if (isPull && flagScrollPull.value) return;
  requestAnimationFrame(() => {
    flagScrollPull.value = isPull;
  });
};

const setAvatar = () => {
  const storedUserInfo = window.localStorage.getItem(LocalStorageKey.userInfo);
  const userInfo = reactive(storedUserInfo ? JSON.parse(storedUserInfo) : {}) as IUser;
  if (userInfo.token && new Date(userInfo.expiresAt) > new Date()) {
    avatarRef.value = userInfo.user.avatar;
  } else {
    window.localStorage.removeItem(LocalStorageKey.userInfo);
  }
};
const getFlatMenu = () => {
  const childrenList: Array<IMenuItemDto> = [];
  request.value.data.forEach((item) => {
    if (item.children) childrenList.push(...toRaw(item.children));
  });
  const parentList = toRaw(request.value.data).map((item) => lodashOmit(item, 'children'));
  return [...parentList, ...childrenList];
};
store.setFlatMenu(getFlatMenu());
useThrottleEventListener('scroll', 200, () => {
  handleScrollPull(window.scrollY);
  setIsScroll();
});
const onOpenMenu = () => {
  isMenu.value = !isMenu.value;
};

onMounted(() => {
  setIsScroll();
  setAvatar();
});

const setIsScroll = () => {
  isScroll.value = document.documentElement.scrollTop === 0;
};

const onClickAvatar = () => {
  const storedUserInfo = window.localStorage.getItem(LocalStorageKey.userInfo);
  if (storedUserInfo) return;
  navigateTo('/login');
};
</script>

<style scoped lang="scss">
@media (max-width: 640px) {
  .header-content {
    padding: 0 1rem;
  }
  .header-none {
    display: none;
  }
}

@keyframes slide-out-top {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-65px);
    opacity: 0;
  }
}

@keyframes slide-out-bottom {
  0% {
    transform: translateY(-65px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.header-exits {
  animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.header-entrances {
  animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.web-title {
  font-family: 'lobster', 'serif';
}

.headerBgc {
  background: var(--bgc-header);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  backdrop-filter: blur(8px);
}

.header-content {
  height: 65px;
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  transition: background-color 300ms ease;
  box-sizing: border-box;
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icons-title {
  transform-origin: center;
  animation: rotate-center 2s linear 0s infinite normal none;
}
</style>

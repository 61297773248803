<template>
  <module-card title="Tags">
    <template #icon>
      <icon-link class="font-size-12px mr-5px">></icon-link>
    </template>
    <div class="tags-box">
      <tag v-for="item in result?.data" :key="item.id" class="m-5px" :name="item.name" :to="`/tag/${item.id}`"></tag>
    </div>
  </module-card>
</template>

<script setup lang="ts">
import { useRandomTags } from '~/http/tags';
import IconLink from '~icons/noto/link';

const { data: result } = await useRandomTags(20);
</script>

<style scoped lang="scss">
.tags-box {
  margin-top: 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}
</style>

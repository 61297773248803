import useHttpFetch from '~/composables/useHttpFetch';
import fetchHttp from '~/http/fetchHttp';
import type { TagDto } from '~/http/types/tag';

const useRandomTags = (sum?: number) => {
  return useHttpFetch<Array<{ name: string; id: string }>>('tag/getRandomTabs', {
    method: 'get',
    params: { sum },
  });
};

const useGetByIdTag = (id: string) => {
  return useHttpFetch<{ name: string; id: string }>('tag/getByIdQueryTag', {
    method: 'get',
    params: { id },
  });
};

const useTagList = (body: { name?: string; pageNum: number; pageSize: number }, options?: { immediate?: boolean }) => {
  return useHttpFetch<ResponseList<TagDto>>('tag/pageTag', {
    ...options,
    method: 'post',
    body,
  });
};

const getTabList = (body: { name?: string; pageNum: number; pageSize: number }) => {
  return fetchHttp<ResponseList<TagDto>>('tag/pageTag', {
    method: 'post',
    body,
  });
};

const updateTag = (body: { name: string; id: string }) => {
  return fetchHttp<Boolean>('tag/updateTagName', {
    method: 'post',
    body,
  });
};

const deleteTag = (id: string) => {
  return fetchHttp<Boolean>('tag/deleteTag', {
    method: 'post',
    body: { id },
  });
};

const createTag = (name: string) => {
  return fetchHttp<Boolean>('tag/createTag', {
    method: 'post',
    body: { name },
  });
};

export { useRandomTags, useGetByIdTag, getTabList, updateTag, deleteTag, createTag, useTagList };

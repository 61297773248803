import type { PropType } from 'vue';
import { Transition } from 'vue';
import styles from './css/smMenuItem.module.scss';
import type { IMenuItemDto } from '~/http/types/menuItem';
import MdiKeyboardArrowRight from '~icons/mdi/keyboard-arrow-right';
import { Icons } from '#components';

export default defineComponent({
  name: 'SmMenuItem',
  props: {
    item: {
      type: Object as PropType<IMenuItemDto>,
      default: () => {},
    },
    onClickTo: {
      type: Function as PropType<(path?: string) => void>,
      required: false,
      default: () => {},
    },
  },
  setup(props: { item: IMenuItemDto; onClickTo: (path?: string) => void }) {
    const expand = ref(false);

    const onClickExpand = () => {
      props.item.children?.length ? (expand.value = !expand.value) : props.onClickTo(props.item.route);
    };

    const onClickModule = (target: IMenuItemDto) => {
      target.route.includes('categories')
        ? props.onClickTo(`${target.route}${target.id}`)
        : props.onClickTo(target.route);
    };

    return () => {
      return (
        <div class={styles.container}>
          <div class="flex-items-center overflow-hidden  mt-6px cursor-pointer rounded-8px c-fontPrimary  transition-all-300 hover:(bg-primary c-#fff!) py-8px px-12px">
            <div onClick={onClickExpand} class="flex-items-center justify-between w-100%">
              <div class="flex-items-center">
                <Icons name={props.item.icon}></Icons>
                <span class="ml-8px font-size-18px color-inherit ">{props.item.name}</span>
              </div>
              <MdiKeyboardArrowRight
                class={`font-size-22px color-inherit transition-transform-300  ${expand.value ? 'rotate-90' : ''} ${props.item?.children?.length ? '' : 'hidden'}`}
              ></MdiKeyboardArrowRight>
            </div>
          </div>
          <Transition
            enterFromClass={styles.fadeEnterFrom}
            leaveToClass={styles.fadeLeaveTo}
            enterActiveClass={styles.fadeEnterActive}
            leaveActiveClass={styles.fadeLeaveActive}
            mode="out-in"
          >
            <div v-show={expand.value} class={`${styles.children} pl-35px`}>
              {props.item.children?.map((item) => {
                return (
                  <div
                    onClick={() => onClickModule(item)}
                    class="cursor-pointer flex-items-center pl-15px rounded-8px overflow-hidden py-8px transition-colors-300 c-fontPrimary  hover:(bg-primary c-#fff!)"
                  >
                    <Icons name={item.icon as string}></Icons>
                    <span class="ml-8px font-size-18px color-inherit ">{item.name}</span>
                  </div>
                );
              })}
            </div>
          </Transition>
        </div>
      );
    };
  },
});

import { defineStore } from 'pinia';
import { LocalStorageKey } from '~/const/sessionKey';

const useAdminStore = defineStore('adminStore', () => {
  const userInfo = reactive({} as IUser);
  if (import.meta.client) {
    const storedUserInfo = window.localStorage.getItem(LocalStorageKey.userInfo);
    Object.assign(userInfo, storedUserInfo ? JSON.parse(storedUserInfo) : {});
  }

  const setUserInfo = (user: IUser) => {
    Object.assign(userInfo, user);
    window.localStorage.setItem(LocalStorageKey.userInfo, JSON.stringify(user));
  };

  return {
    setUserInfo,
    userInfo,
  };
});

export default useAdminStore;

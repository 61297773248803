import type { ICreateMenuItemDto, IMenuItemDto } from './types/menuItem';
import fetchHttp from '~/http/fetchHttp';

const useMenuItemList = (flat?: Boolean, options?: { immediate?: boolean }) => {
  return useHttpFetch<Array<IMenuItemDto>>('menuItem/getMenuItems', { ...options, method: 'get', params: { flat } });
};

const useGetByIdMenuItem = (id: string) => {
  return useHttpFetch<IMenuItemDto>('menuItem/getByIdQueryItem', { method: 'get', params: { id } });
};

const getByIdMenuItem = (id: string) => {
  return fetchHttp<IMenuItemDto>('menuItem/getByIdQueryItem', { method: 'get', params: { id } });
};

const createMenuItem = (data: ICreateMenuItemDto) => {
  return fetchHttp<Boolean>('menuItem/createMenuItem', {
    method: 'post',
    body: data,
  });
};

const updateMenuItem = (data: IMenuItemDto) => {
  return fetchHttp<Boolean>('menuItem/updateMenuItem', {
    method: 'post',
    body: data,
  });
};

const deleteMenuItem = (id: string) => {
  return fetchHttp<Boolean>('menuItem/deleteMenuItem', {
    method: 'get',
    params: { id },
  });
};

export { useMenuItemList, useGetByIdMenuItem, getByIdMenuItem, createMenuItem, updateMenuItem, deleteMenuItem };

<template>
  <client-only>
    <Teleport to="#app-main">
      <div class="drawer-content" :class="contentClass">
        <transition name="fade">
          <div
            v-show="!open"
            :class="['drawer-mask', maskClass]"
            @touchstart.stop="onTouchmove($event)"
            @click.stop="onClickMask"
          ></div>
        </transition>
        <transition name="right">
          <div
            v-if="isMainDestroy"
            v-show="!open"
            class="drawer-main"
            :style="{
              width: width,
              '--drawer-width': width,
            }"
            :class="[isDark ? 'dark' : 'light', 'mainClass']"
          >
            <slot></slot>
          </div>
        </transition>
      </div>
    </Teleport>
  </client-only>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import useStore from '~/store';

type Props = {
  isMask?: boolean;
  width?: number;
  contentClass?: string;
  maskClass?: string;
  mainClass?: string;
  isDestroy?: boolean;
};

const width = computed(() => {
  return `${props.width}px`;
});
const open = defineModel<boolean>('open', {
  default: true,
});

const emit = defineEmits<{
  onClickMask: [];
}>();

const isMainDestroy = computed(() => {
  if (!open.value) return true;
  return !props.isDestroy;
});

const props = withDefaults(defineProps<Props>(), {
  isMask: true,
  width: 300,
  contentClass: '',
  maskClass: '',
  mainClass: '',
  isDestroy: false,
});

const onTouchmove = (event: TouchEvent) => {
  event.preventDefault();
  onClickMask();
};

watch(open, (newVal) => {
  document.body.style.overflow = !newVal ? 'hidden' : 'auto';
});

const store = useStore();
const { isDark } = storeToRefs(store);

const onClickMask = () => {
  if (!props.isMask) return;
  emit('onClickMask');
  open.value = true;
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.drawer-content {
  overflow: hidden;
  box-sizing: border-box;
}

.drawer-mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--mask-bgc);
  width: 100%;
  height: 100%;
  transform: translateZ(0);
  z-index: 999;
  backdrop-filter: saturate(180%) blur(20px);
}

.light {
  background-color: #f7f9fe;
}

.dark {
  background: var(--bgc-body);
}

.right-enter-active,
.right-leave-active {
  transition: right 0.5s ease;
}
.right-enter-from {
  /* 移除的同时 */
  right: calc(var(--drawer-width) * -1) !important; /* 使用CSS变量表示宽度 */
}
.right-leave-to {
  /*离开动画的结束状态。在一个离开动画被触发后的下一帧被添加*/
  right: calc(var(--drawer-width) * -1) !important; /* 使用CSS变量表示宽度 */
}

.drawer-main {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  transform: translateZ(0);
  z-index: 1000;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
</style>

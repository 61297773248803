import type {
  IArticleDto,
  IArticleListParams,
  IArticleTagTypeCount,
  ICreateArticleDto,
  IUpdateArticleDto,
} from './types/article';
import fetchHttp from '~/http/fetchHttp';

const useArticleList = (
  key: string,
  options: IArticleListParams,
  transform?: (res: Response<ResponseList<IArticleDto>>) => Response<ResponseList<IArticleDto>>,
) => {
  return useHttpFetch<ResponseList<IArticleDto>>('article/getArticleList', {
    method: 'post',
    key,
    body: options,
    watch: false,
    deep: false,
    transform,
  });
};

const getArticleList = (options: IArticleListParams) => {
  return fetchHttp<ResponseList<IArticleDto>>('article/getArticleList', {
    method: 'POST',
    body: options,
  });
};

const useArticleDetail = (id: number) => {
  return useHttpFetch<IArticleDto>('article/getIdArticle', { method: 'get', params: { id } });
};

const setArticleLike = (id?: number) => {
  return fetchHttp<boolean>(
    'article/thumbLikeArticle',
    {
      method: 'get',
      params: { id },
    },
    false,
  );
};
const useArticleTagTypeCount = () => {
  return useHttpFetch<IArticleTagTypeCount>('article/getArticleTagTypeCount', {
    method: 'get',
  });
};

const createArticle = (data: ICreateArticleDto) => {
  return fetchHttp<Boolean>('article/createArticle', {
    method: 'post',
    body: data,
  });
};

const deleteIdArticle = (id: number) => {
  return fetchHttp<Boolean>('article/deleteIdArticle', {
    method: 'get',
    params: { id },
  });
};

const getArticleDetail = (id: number) => {
  return fetchHttp<IArticleDto>('article/getIdArticle', {
    method: 'get',
    params: { id },
  });
};

const updateArticle = (updateBody: IUpdateArticleDto) => {
  return fetchHttp<Boolean>('article/updateArticle', {
    method: 'post',
    body: updateBody,
  });
};

export {
  useArticleList,
  useArticleDetail,
  setArticleLike,
  useArticleTagTypeCount,
  getArticleList,
  createArticle,
  deleteIdArticle,
  getArticleDetail,
  updateArticle,
};
